import camelize from "camelize-ts";
import { defaultBaseUrl, defaultRealm } from "./constants.js";
import { fetchWithError } from "./fetchWithError.js";
import { stringifyQueryParams } from "./stringifyQueryParams.js";
export const getToken = async (settings) => {
    // Construct URL
    const baseUrl = settings.baseUrl || defaultBaseUrl;
    const realmName = settings.realmName || defaultRealm;
    const url = `${baseUrl}/realms/${realmName}/protocol/openid-connect/token`;
    // Prepare credentials for openid-connect token request
    // ref: http://openid.net/specs/openid-connect-core-1_0.html#TokenEndpoint
    const credentials = settings.credentials || {};
    const payload = stringifyQueryParams({
        username: credentials.username,
        password: credentials.password,
        grant_type: credentials.grantType,
        client_id: credentials.clientId,
        totp: credentials.totp,
        ...(credentials.offlineToken ? { scope: "offline_access" } : {}),
        ...(credentials.scopes ? { scope: credentials.scopes.join(" ") } : {}),
        ...(credentials.refreshToken
            ? {
                refresh_token: credentials.refreshToken,
                client_secret: credentials.clientSecret,
            }
            : {}),
    });
    const options = settings.requestOptions ?? {};
    const headers = new Headers(options.headers);
    if (credentials.clientSecret) {
        headers.set("Authorization", atob(credentials.clientId + ":" + credentials.clientSecret));
    }
    headers.set("content-type", "application/x-www-form-urlencoded");
    const response = await fetchWithError(url, {
        ...options,
        method: "POST",
        headers,
        body: payload,
    });
    const data = await response.json();
    return camelize(data);
};
